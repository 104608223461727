import { useEffect, useRef } from "react";
import { QueryFunctionContext, useQuery } from "react-query";

import axios from "axios";

import { FORECASTING_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { useForecastingContext } from "../contexts/useForecastingContext";
import {
  ForecastWell,
  WellHierarchy,
  WellHierarchyCollection
} from "../models/forecastWell";
import { ForecastHierarchy } from "../models/hierarchy";

const API_URL = `${FORECASTING_SERVICE_ADDRESS}/api/v1/hierarchy`;

export const FetchWellHierarchyQueryKey = "fetchWellHierarchy";

const fetchAllHierarchies = async (
  keys: QueryFunctionContext
): Promise<WellHierarchyCollection> => {
  const hierarchy =
    keys.queryKey.length > 1 ? (keys.queryKey[1] as ForecastHierarchy) : { id: "" };
  if (!hierarchy?.id) return { hierarchy: [], leafs: [] } as WellHierarchyCollection;
  const filterId = (keys.queryKey.length > 2 ? keys.queryKey[2] : { id: "" }) as {
    id: string;
  };
  const response = await axios.get<WellHierarchy[]>(
    `${API_URL}/${hierarchy?.id}/well/${filterId.id}`
  );
  const data = response.data;
  const leafs = populateKeys("root", data);
  return {
    hierarchy: data,
    leafs
  } as WellHierarchyCollection;
};

const populateKeys = (parentTitle: string, data: WellHierarchy[]): ForecastWell[] => {
  let leafs = [];
  for (const item of data) {
    item.title = item.hierarchyName;
    item.selectable = false;
    item.key = parentTitle + item.title;
    leafs = leafs.concat(populateKeys(item.key, item.children));
    if (item.leafs.length > 0) {
      for (const leaf of item.leafs) {
        leaf.title = leaf.entityName;
        leaf.key = leaf.uwi;
        leaf.selectable = true;
        leaf.parentKey = item.key;
      }
      item.children = item.leafs as unknown as WellHierarchy[];
      leafs = leafs.concat(...item.leafs);
    }
  }
  return leafs;
};

const useFetchWellHierarchy = (
  hierarchy: ForecastHierarchy,
  filterId: { id: string }
) => {
  const { state, dispatch } = useForecastingContext();
  const previousHierarchyIdRef = useRef(state.selectedHierarchy?.id);

  const result = useQuery<WellHierarchyCollection>(
    [FetchWellHierarchyQueryKey, hierarchy, filterId],
    fetchAllHierarchies,
    {
      refetchOnWindowFocus: false,
      //disable this if show nearby wells is on
      //because we don't want to refetch the hierarchy
      //since the map would have changed
      enabled: !state.zoomToWell.zoom || previousHierarchyIdRef.current !== hierarchy?.id,
      onSuccess: () => {
        previousHierarchyIdRef.current = hierarchy?.id;
      }
    }
  );

  useEffect(() => {
    if (result.data) {
      dispatch({
        type: "SET_WELL_HIERARCHY",
        payload: result.data
      });
    }
  }, [result.data, dispatch]);

  useEffect(() => {
    dispatch({
      type: "SET_WELL_HIERARCHY_LOADING",
      payload: result.isLoading
    });
  }, [result.isLoading, dispatch]);

  return result;
};

export default useFetchWellHierarchy;
