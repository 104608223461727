/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import useResizeObserver from "@react-hook/resize-observer";
import { useCallback, useEffect, useRef, useState } from "react";
import GridLayout, { Layout } from "react-grid-layout";
import { useSelector } from "react-redux";
import * as portals from "react-reverse-portal";

import { Subscription } from "rxjs";
import { ISelectedWell } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";
import { uid } from "utils";

import { MapEntity, fetchMapEntities } from "api/map";

import { GridWidget } from "models/dashboard";
import { Dashboard, DashboardWidget } from "models/workspace";

import { applyChartDefaults } from "components/chart/utils";
import { useFilterWatcher } from "components/filter";
import { applyMultiPhaseChartDefaults } from "components/multiphase-chart/util";
import { useWorkspaceContext } from "components/workspace/hooks/useWorkspaceContext";

import { EntityKind } from "../../models/entityKind";
import "./DashboardPane.scss";
import Widget from "./Widget";
import WidgetWrapper from "./WidgetWrapper";
import {
  MidstreamChartWidgetKey,
  TypeWellDetailsWidgetKey,
  TypeWellEditorWidgetKey
} from "./constants/widgets.constants";
import { useDashboardContext, useDashboardDispatch } from "./hooks";
import { calculateIdealWidgetSize } from "./utils";

let dashboardSub: Subscription = null;

const mapPortalNode = portals.createHtmlPortalNode();
const errorTitle = "We're sorry, it looks like we're having issues.";

export interface DashboardPaneModel {
  className?: string;
  isPopoutDashboard?: boolean;
}

export default function DashboardPane({
  className = "",
  isPopoutDashboard = false
}: DashboardPaneModel) {
  // context and hooks

  // Incompatible with popout dashboards
  // See note in hook
  useFilterWatcher(isPopoutDashboard, EntityKind.Well);
  useFilterWatcher(isPopoutDashboard, EntityKind.Facility);

  const dashboardDispatch = useDashboardDispatch();
  const { error: workspaceError } = useWorkspaceContext();
  const {
    dashboard,
    isLoading: dashboardLoading,
    error,
    dashboardRefreshObserver,
    dashboardMode
  } = useDashboardContext();

  // state
  const [columns, setColumns] = useState(12);
  const [designMode, setDesignMode] = useState(false);
  const [containerWidth, setWidth] = useState(1200);
  const [, setHeight] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [node, setNode] = useState(null);
  const [refreshTxn, setRefreshTxn] = useState<string>(null);
  const [rowHeight, setRowHeight] = useState(30);
  const [showFullscreen, setShowFullscreen] = useState({
    mb: false
  });
  const [wells, setWells] = useState({});
  const [widgets, setWidgets] = useState<GridWidget[]>();

  // refs
  const containerRef = useRef(null);
  const margin = useRef<[number, number]>([0, 0]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const globalSettingsRefs = useRef<{ [key: string]: any }>({});

  // state from store
  const selectedWells = useSelector((state: RootState) => state.map.selectedWells);
  const txnId = useSelector((state: RootState) => state.map.txnId);
  const useGlobalNormalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.useNormalizeBy
  );
  const globalNormalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.globalNormalizeBy
  );
  const dataSourceSetting = useSelector(
    (state: RootState) => state.app.pdenDataSourceSetting
  );

  // Update the design mode.
  useEffect(() => {
    setDesignMode(dashboardMode === "edit");
  }, [dashboardMode]);

  // Handle the selected wells.
  useEffect(() => {
    async function updateSelectedWells() {
      const result = await fetchMapEntities(txnId.id, Object.keys(selectedWells));
      if (result.ok) {
        const mapEntities = result.value;
        const temp: { [uwi: string]: ISelectedWell } = {};
        if (mapEntities && mapEntities.length > 0) {
          const entitiesDict: { [name: string]: MapEntity } = {};
          for (const ent of mapEntities) {
            entitiesDict[ent.uwi] = ent;
          }
          for (const selected of Object.keys(selectedWells)) {
            if (entitiesDict[selected]) {
              temp[selected] = {
                color: entitiesDict[selected].color,
                Uwi: selectedWells[selected].Uwi,
                group: entitiesDict[selected].group
              };
            }
          }
        }
        setWells(temp);
      } else {
        setWells(selectedWells);
      }
    }

    //update selected wells colors when the txnid changes
    updateSelectedWells();
  }, [selectedWells, txnId]);

  const getRowHeight = (height: number) => {
    return Math.max(Math.trunc(height / 12.0), 30);
  };

  useResizeObserver(containerRef, (entry) => {
    setWidth(entry.contentRect.width);
    setHeight(entry.contentRect.height);
    setRowHeight(getRowHeight(entry.contentRect.height));
  });

  // Reset the component back to initial state.
  useEffect(() => {
    if (dashboardRefreshObserver && !dashboardSub) {
      dashboardSub = dashboardRefreshObserver.subscribe((refreshTxn: string) => {
        setRefreshTxn(refreshTxn);
      });
    }
    return () => {
      if (dashboardSub) {
        dashboardSub.unsubscribe();
        dashboardSub = null;
      }
    };
  }, [dashboardRefreshObserver]);

  // Update the global settings references when the global settings change for the current dashboard only
  useEffect(() => {
    if (dashboard) {
      dashboard.widgets.forEach((item: DashboardWidget) => {
        const key = `${item.type}::${item.widgetId}`;

        globalSettingsRefs.current[key] = {
          useNormalizeBy: useGlobalNormalizeBy,
          normalizeBy: globalNormalizeBy,
          timeStep: dataSourceSetting?.timeStep,
          source: dataSourceSetting?.source
        };
      });
    }
  }, [globalNormalizeBy, useGlobalNormalizeBy, dataSourceSetting]);

  // Build the widgets state when the dashboard changes or there is a new dashboard refresh transaction.
  useEffect(() => {
    if (dashboard) {
      const activeDashboard: Dashboard = {
        ...dashboard
      };

      const gridLayoutItems = activeDashboard.widgets.map(
        (item: DashboardWidget, index: number): GridWidget => {
          const gridLayoutItem: GridWidget = {
            x: item.x,
            y: item.y,
            w: item.width,
            h: item.height,
            i: item.widgetId,
            name: item.type,
            static: false,
            ref: item.widgetId,
            componentIndex: index,
            component: item.type,
            componentState: undefined,
            componentOptions: undefined
          };

          const currentGlobalSettings = {
            useNormalizeBy: useGlobalNormalizeBy,
            normalizeBy: globalNormalizeBy,
            timeStep: dataSourceSetting?.timeStep,
            source: dataSourceSetting?.source
          };

          const key = `${item.type}::${item.widgetId}`;
          const previousGlobalSettings = globalSettingsRefs.current[key] || {};
          const globalSettingsChanged =
            JSON.stringify(previousGlobalSettings) !==
            JSON.stringify(currentGlobalSettings);

          //load from session before defaults
          const sessionData = sessionStorage.getItem(`${item.type}::${item.widgetId}`);
          if (sessionData) {
            gridLayoutItem.componentState = JSON.parse(sessionData);
            // Get global normalization and data source settings instead of session storage
            // as they should trump the chart normalization and data source settings
            if (gridLayoutItem.component === "chart" && globalSettingsChanged) {
              Object.assign(gridLayoutItem.componentState, currentGlobalSettings);
            }
          } else if (item.settings) {
            gridLayoutItem.componentState = JSON.parse(item.settings);
          }

          // Update reference to global settings after session storage is loaded
          globalSettingsRefs.current[key] = currentGlobalSettings;

          switch (item.type) {
            case "map": {
              // Ensure the map widget has specific ID, ref and portal to ensure consistency
              // when switching dashboards.
              gridLayoutItem.i = "mb";
              gridLayoutItem.ref = "mb";
              gridLayoutItem.componentOptions = {
                portalNode: mapPortalNode
              };
              break;
            }
            case "chart":
            case MidstreamChartWidgetKey: {
              gridLayoutItem.componentOptions = {
                portalNode: portals.createHtmlPortalNode()
              };
              gridLayoutItem.componentState = applyChartDefaults(
                gridLayoutItem.componentState
              );
              gridLayoutItem.componentState.id = item.widgetId;
              break;
            }
            case "multiphase-chart": {
              gridLayoutItem.componentOptions = {
                portalNode: portals.createHtmlPortalNode()
              };
              gridLayoutItem.componentState = applyMultiPhaseChartDefaults(
                gridLayoutItem.componentState
              );
              gridLayoutItem.componentState.id = item.widgetId;
              break;
            }
            case "completion":
            case "survey-vis":
            case "threed":
            case "xdaviewer":
            case "xda": {
              gridLayoutItem.componentOptions = {
                portalNode: portals.createHtmlPortalNode()
              };
              break;
            }
            case TypeWellEditorWidgetKey: {
              gridLayoutItem.componentOptions = {
                portalNode: portals.createHtmlPortalNode()
              };
              break;
            }
            case TypeWellDetailsWidgetKey: {
              gridLayoutItem.componentOptions = {
                portalNode: portals.createHtmlPortalNode()
              };
              break;
            }
            default: {
              break;
            }
          }
          return gridLayoutItem;
        }
      );

      // gridLayoutItems should contains all the widgets that we have on screen
      if (!isPopoutDashboard && !gridLayoutItems.find((x) => x.i === "mb")) {
        // Add a hidden map
        gridLayoutItems.push({
          x: 0,
          y: 0,
          w: 0,
          h: 0,
          minW: 0,
          minH: 0,
          i: "mb",
          name: "map",
          static: false,
          ref: "mb",
          componentIndex: gridLayoutItems.length,
          component: "map",
          componentState: undefined,
          componentOptions: {
            portalNode: mapPortalNode
          }
        });
      }

      setWidgets(gridLayoutItems);
      if (activeDashboard.layout.width) {
        setColumns(activeDashboard.layout.width);
      }

      // Reset the full screen
      setShowFullscreen({
        mb: false
      });
      setIsFullscreen(false);
      setNode(null);
    }
  }, [dashboard, refreshTxn, designMode]);

  // Take action when a widget is removed from the dashboard.
  const onRemoveWidget = useCallback(
    (widget: GridWidget) => {
      if (!dashboard?.widgets) return;

      const modifiedWidgets: DashboardWidget[] = [];
      dashboard.widgets.forEach((x) => {
        const copy = { ...x };
        // The map uses a special 'i' value, so it needs to be treated special.
        if (widget.component === "map") {
          if (x.type !== "map") {
            modifiedWidgets.push(copy);
          }
        } else if (copy.widgetId !== widget.i) {
          modifiedWidgets.push(copy);
        }
      });

      const modifiedDashboard: Dashboard = {
        ...dashboard,
        widgets: modifiedWidgets
      };

      dashboardDispatch({
        payload: {
          isModified: true,
          dashboard: modifiedDashboard
        }
      });
    },
    [dashboard, dashboardDispatch]
  );

  // Take action when the layout changes, usually due to a widget moving or being resized.
  const onLayoutChanged = useCallback(
    (layout: Layout[]) => {
      if (!dashboard?.widgets || dashboardMode !== "edit") return;

      // Ignore if the widget length does not match as this is will handled by onDrop.
      if (dashboard.widgets.length != layout.filter((l) => l.w > 0 && l.h > 0).length)
        return;

      const modifiedWidgets: DashboardWidget[] = [];
      dashboard.widgets.forEach((x) => {
        const match = layout.find(
          (l) =>
            l.i === x.widgetId || (x.type === "map" && l.i === "mb" && l.w > 0 && l.h > 0)
        );
        if (match) {
          const copy = { ...x };
          copy.x = match.x;
          copy.y = match.y;
          copy.width = match.w;
          copy.height = match.h;
          modifiedWidgets.push(copy);
        }
      });

      const modifiedDashboard: Dashboard = {
        ...dashboard,
        widgets: modifiedWidgets
      };

      dashboardDispatch({
        payload: {
          isModified: true,
          dashboard: modifiedDashboard
        }
      });
    },
    [dashboard, dashboardDispatch, dashboardMode]
  );

  // Take action when a new widget is dropped onto the dashboard
  const onDrop = useCallback(
    (layout: Layout[], layoutItem: Layout, e) => {
      if (!layoutItem || !dashboard?.widgets) return;

      const data = JSON.parse(e.dataTransfer.getData("dragData"));
      const modifiedWidgets: DashboardWidget[] = [];
      dashboard.widgets.forEach((x) => {
        const match = layout.find(
          (l) => l.i === x.widgetId || (x.type === "map" && l.i === "mb")
        );
        if (match) {
          const copy = { ...x };
          copy.x = match.x;
          copy.y = match.y;
          modifiedWidgets.push(copy);
        }
      });
      const id = uid();

      // Calculate the ideal size of the dropped widget based on the current layout.
      const { x, y, w, h } = calculateIdealWidgetSize(
        data.component,
        layoutItem,
        layout,
        dashboard?.layout
      );

      const newWidget: DashboardWidget = {
        widgetId: id,
        type: data.component,
        title: data.component,
        x: x,
        y: y,
        width: w,
        height: h,
        settings: JSON.stringify(data.componentState)
      };

      modifiedWidgets.push(newWidget);

      const modifiedDashboard: Dashboard = {
        ...dashboard,
        widgets: modifiedWidgets
      };

      dashboardDispatch({
        payload: {
          isModified: true,
          dashboard: modifiedDashboard
        }
      });
    },
    [dashboard, dashboardDispatch]
  );

  const setFullscreen = (item) => (isFullscreen) => {
    const copy = { ...showFullscreen };
    copy[item.ref] = isFullscreen;
    setShowFullscreen(copy);
    setNode(item.componentOptions.portalNode);
    setIsFullscreen(isFullscreen);
  };

  // Handle an error loading the dashboard.
  if ((workspaceError || error) && !dashboardLoading) {
    return (
      <DashboardErrorContainer
        className={`ComponentPane ${className}`}
        ref={containerRef}>
        <h5>{errorTitle}</h5>
      </DashboardErrorContainer>
    );
  }

  return (
    <div className={`ComponentPane ${className}`} ref={containerRef}>
      {widgets && (
        <GridLayout
          layout={widgets}
          className="layout"
          isDraggable={designMode}
          draggableHandle=".widget-title"
          isResizable={designMode}
          resizeHandles={designMode ? ["se"] : []}
          onLayoutChange={(layout: Layout[]) => onLayoutChanged(layout)}
          isDroppable={designMode}
          onDrop={(layout: Layout[], layoutItem: Layout, e) =>
            onDrop(layout, layoutItem, e)
          }
          compactType="vertical"
          onDropDragOver={() => {
            return { w: 2, h: 2 };
          }}
          cols={columns}
          margin={margin.current}
          width={containerWidth}
          rowHeight={rowHeight}>
          {widgets.map((item: GridWidget) => {
            return (
              <div key={item.i}>
                <WidgetWrapper
                  widget={item}
                  designMode={designMode}
                  onRemoveWidget={(widget: GridWidget) => onRemoveWidget(widget)}>
                  <Widget
                    widget={item}
                    showFullscreen={showFullscreen[item.ref]}
                    onFullscreen={setFullscreen(item)}
                    selectedWells={selectedWells}
                    wells={wells}
                  />
                </WidgetWrapper>
              </div>
            );
          })}
        </GridLayout>
      )}

      {isFullscreen && dashboardMode === "explore" && (
        <div className="fullscreen">
          <portals.OutPortal node={node}></portals.OutPortal>
        </div>
      )}
    </div>
  );
}

const DashboardErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;
