import { useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";

import tdistance from "@turf/distance";
import {
  LineString,
  Point,
  geometryCollection,
  lineString,
  point,
  polygon as tPolygon
} from "@turf/helpers";
import transformTranslate from "@turf/transform-translate";
import {
  WELL_LAYER,
  XDA_INTERCEPT_LAYER,
  XDA_LABEL
} from "constants/mapLayers.constants";
import { PointLike } from "mapbox-gl";
import { setXdaLineRequest } from "store/features";
import { RootState } from "store/rootReducer";
import { Vector2 } from "three";

import { getPerpendicularLine } from "api/map";

import { ToolbarButton } from "components/base";
import { useDashboardContext } from "components/dashboard/hooks/useDashboardContext";
import { useGeomBinContext } from "components/geom-bin/hooks/useGeomBinContext";
import Line from "components/icons/Line";

import { useMapContext } from "../hooks/useMapContext";
import { useMapDispatch } from "../hooks/useMapDispatch";
import { emptyFeatureCollection } from "../utils/emptyFeatureCollection";
import { getXdaLineNearCenterOfMap } from "../utils/getXdaLineNearCenterOfMap";
import { setXdaLine } from "../utils/mapboxHelper";

export interface XdaLineMarkerComponentModel {
  localState;
  selectionPolygonRef;
  getAndSetFeaturesIntersectingPolygon;
  drawRef;
}

export default function XdaLineMarker({
  localState,
  getAndSetFeaturesIntersectingPolygon,
  drawRef,
  selectionPolygonRef
}: XdaLineMarkerComponentModel) {
  const [isInVisDashboard, setIsInVisDashboard] = useState(false);
  const { mapbox, isMapInitialized } = useMapContext();
  const mapDispatch = useMapDispatch();
  const { isActive: isGeomBinOpen } = useGeomBinContext();
  const dashboardRef = useRef({
    isInSingleDashboard: false,
    isInVisDashboard
  });
  useHotkeys(
    "esc",
    () => {
      if (!mapbox) {
        return;
      }
      clearXda();
    },
    {},
    [mapbox]
  );

  const { capabilities } = useDashboardContext();
  useEffect(() => {
    if (!capabilities) return;

    dashboardRef.current = {
      isInSingleDashboard: capabilities.hasSingleWellWidget,
      isInVisDashboard: capabilities.hasXdaWidget || capabilities.hasThreeDWidget
    };
    setIsInVisDashboard(dashboardRef.current.isInVisDashboard);
  }, [capabilities]);

  const xdaInterceptData = useSelector((root: RootState) => root.map.xdaInterceptData);
  const dispatch = useDispatch();
  const isLineSelectRef = useRef(false);

  const setLineSelect = (value) => {
    if (!drawRef.current) return;

    if (value) {
      drawRef.current.changeMode("two_point_line");
    } else {
      drawRef.current.deleteAll();
    }

    isLineSelectRef.current = value;
  };

  function clearXda() {
    clearXdaInterceptLine(mapbox);
    clearXdaLabel(mapbox);
    if (drawRef?.current) {
      drawRef.current.deleteAll();
    }
    mapDispatch({
      payload: {
        selectedFeatures: []
      }
    });
    mapDispatch({
      payload: {
        highlightedFeatures: { line: [], point: [], facility: [] }
      }
    });
  }

  async function makeXdaLineRequest(geom, selectedWells = {}) {
    const coords = geom?.coordinates;
    if (!coords) {
      return;
    }
    const poly = tPolygon([
      [
        [coords[0][0], coords[0][1]],
        [coords[1][0], coords[1][1]],
        [coords[1][0] + 0.0001, coords[1][1] + 0.0001],
        [coords[0][0], coords[0][1]]
      ]
    ]);
    geom = poly;
    setXdaLine(
      {
        start: { x: coords[0][0], y: coords[0][1] },
        end: { x: coords[1][0], y: coords[1][1] }
      },
      [],
      mapbox
    );
    selectionPolygonRef.current = geom;
    if (!selectedWells || Object.keys(selectedWells).length === 0) {
      [selectedWells] = await getAndSetFeaturesIntersectingPolygon(poly);
    }
    dispatch(
      setXdaLineRequest({
        line: coords,
        wells: selectedWells
      })
    );
  }

  function clearXdaInterceptLine(mapbox) {
    if (!mapbox) {
      return;
    }
    const xda = mapbox.getSource(XDA_INTERCEPT_LAYER);
    if (!xda) {
      return;
    }
    xda.setData(emptyFeatureCollection);
  }

  function clearXdaLabel(mapbox) {
    if (!mapbox) {
      return;
    }
    const label = mapbox.getSource(XDA_LABEL);
    if (!label) {
      return;
    }
    label.setData(emptyFeatureCollection);
  }

  async function onClickOnMap(e) {
    if (
      !mapbox ||
      isLineSelectRef.current ||
      !dashboardRef?.current ||
      (!dashboardRef.current.isInSingleDashboard &&
        !dashboardRef.current.isInVisDashboard)
    ) {
      //only register click if dashboard has vis or single well
      return;
    }
    if (!mapbox.getLayer(WELL_LAYER)) {
      return;
    }
    const buffer = 2;
    const bbox = [
      [e.point.x - buffer, e.point.y - buffer],
      [e.point.x + buffer, e.point.y + buffer]
    ] as [PointLike, PointLike];
    if (!mapbox.getLayer(WELL_LAYER)) {
      return;
    }
    const features = mapbox.queryRenderedFeatures(bbox, {
      layers: [WELL_LAYER]
    });
    if (features.length === 0 || !features[0]) {
      return;
    }
    const selectedWells = {};
    const feat = features[0];
    if (!feat) {
      return;
    }

    selectedWells[feat.properties.Uwi] = feat.properties;
    //get perpendicular line
    const perpedicularLine = await getPerpendicularLine(feat.properties.Uwi);
    if (
      perpedicularLine?.[0]?.x &&
      perpedicularLine?.[1]?.x &&
      perpedicularLine?.[0]?.y &&
      perpedicularLine?.[1]?.y
    ) {
      const feat = lineString([
        [perpedicularLine[0]?.x, perpedicularLine[0]?.y],
        [perpedicularLine[1]?.x, perpedicularLine[1]?.y]
      ]);
      await makeXdaLineRequest(feat.geometry, selectedWells);
    }
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = xdaInterceptData;
    if (!mapbox || !xdaInterceptData || data.line.length === 0) {
      return;
    }

    const line = {
      type: "LineString",
      coordinates: [
        [data.line.start.x, data.line.start.y],
        [data.line.end.x, data.line.end.y]
      ]
    } as LineString;

    const geoms = xdaInterceptData.points.map((p) => {
      return { type: "Point", coordinates: [p.x, p.y] };
    }) as Point[];

    const gcoll = geometryCollection(geoms);
    gcoll.geometry.geometries.push(line);
    const source = mapbox.getSource(XDA_INTERCEPT_LAYER) as mapboxgl.GeoJSONSource;
    if (!source) {
      return;
    }
    localState.current.xdaInterceptLine = gcoll;
    source.setData(gcoll);
  }, [xdaInterceptData]);

  useEffect(() => {
    function onMouseMove(e) {
      const coords = e.lngLat;
      const prevMouseCoordinate = localState.current.currentMouseCoordinate;

      if (localState.current.xdaInterceptLine) {
        const from = point([prevMouseCoordinate.lng, prevMouseCoordinate.lat]);
        const to = point([coords.lng, coords.lat]);

        //angle from north in axis degree
        let angle =
          new Vector2(
            coords.lng - prevMouseCoordinate.lng,
            coords.lat - prevMouseCoordinate.lat
          ).angle() *
          (180 / Math.PI);
        angle = 360 - (angle - 90);
        const distance = tdistance(from, to, { units: "kilometers" });
        transformTranslate(localState.current.xdaInterceptLine, distance, angle, {
          mutate: true
        });
        const source = mapbox.getSource(XDA_INTERCEPT_LAYER) as mapboxgl.GeoJSONSource;
        source.setData(localState.current.xdaInterceptLine);
        localState.current.currentMouseCoordinate = coords;
      }
    }

    async function onDrawEnd(e) {
      //calls when finished drawing
      if (e.features.length > 0) {
        const feature = e.features[0];

        const geom = feature.geometry;
        if (geom.type !== "LineString") {
          return;
        }
        //convert linestring to polygon
        await makeXdaLineRequest(geom);
        if (drawRef?.current) {
          drawRef.current.deleteAll();
        }
        setLineSelect(false);
      }
    }

    function onMouseUp() {
      //get centroid of line and pass it to xda viewer
      const geom = localState.current.xdaInterceptLine;
      if (!geom || !geom.geometry || !geom.geometry.geometries) {
        return;
      }
      const line = geom.geometry.geometries.filter((x) => x.type === "LineString");
      if (!line) {
        return;
      }

      mapbox.off("mousemove", onMouseMove);
      mapbox.off("mouseUp", onMouseUp);
      if (localState.current.xdaLineMoved) {
        setTimeout(async () => await makeXdaLineRequest(line[0]), 500);
      }
      localState.current.xdaLineMoved = false;
    }

    if (!mapbox) {
      return;
    }

    mapbox.on("mousedown", XDA_INTERCEPT_LAYER, (e) => {
      e.preventDefault();

      const state = localState.current;
      if (!state) {
        return;
      }
      const coords = e.lngLat;
      state.currentMouseCoordinate = coords;
      state.xdaLineMoved = true;

      mapbox.on("mousemove", onMouseMove);
      mapbox.on("mouseup", onMouseUp);
    });

    mapbox.on("draw.create", onDrawEnd);
    mapbox.on("click", onClickOnMap);

    return () => {
      drawRef.current = undefined;
    };
  }, [drawRef, localState, mapbox]);

  async function setDefaultXdaLine() {
    //is vis dash so we want to draw xda line at random well near center of screen
    if (!mapbox) {
      return;
    }

    const { line } = await getXdaLineNearCenterOfMap(mapbox);

    if (!line) {
      return;
    }

    const poly = tPolygon([
      [
        [line.start.x, line.start.y],
        [line.end.x, line.end.y],
        [line.end.x + 0.0001, line.end.y + 0.0001],
        [line.start.x, line.start.y]
      ]
    ]);
    const [selectedWells] = await getAndSetFeaturesIntersectingPolygon(poly);

    dispatch(
      setXdaLineRequest({
        line: [
          [line.start.x, line.start.y],
          [line.end.x, line.end.y]
        ],
        wells: selectedWells
      })
    );
  }

  useEffect(() => {
    async function setInitialXda() {
      let count = 0;
      while ((!mapbox || !mapbox.areTilesLoaded) && count < 5) {
        await new Promise((r) => setTimeout(r, 1000));
        count++;
      }
      setDefaultXdaLine();
    }

    if (isInVisDashboard && isMapInitialized) {
      setInitialXda();
    } else {
      clearXda();
    }
  }, [isInVisDashboard, isMapInitialized]);

  if (!isInVisDashboard || !mapbox || isGeomBinOpen) {
    return <></>;
  }
  return (
    <ToolbarButton
      active={isLineSelectRef.current}
      disabled={drawRef?.current === undefined}
      icon={<Line />}
      onToggle={setLineSelect}
      tooltipText="Toggle Line Selection"
      styleKey="mapToolbar"
    />
  );
}
