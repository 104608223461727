import { ReactNode, memo } from "react";

import { Dropdown, DropdownProps } from "antd";
import classnames from "classnames";
import styled from "styled-components/macro";

import { Tooltip } from "components/base";

const BUTTON_ACTIVE_BACKGROUND_COLOR = "var(--color-primary)";
const BUTTON_ACTIVE_COLOR = "white";
const BUTTON_DEFAULT_BACKGROUND_COLOR = "transparent";
const BUTTON_DEFAULT_COLOR = "var(--color-icon)";
const BUTTON_HOVER_COLOR = "var(--color-text)";
const BUTTON_HOVER_COLOR_DANGER = "var(--color-danger)";

const MEDIUM_STYLE_BUTTON = {
  buttonSize: 30,
  menuButtonLeft: 22,
  menuButtonSize: 5.5,
  menuButtonTop: 22
};

const STYLE_MAP = {
  default: {
    buttonSize: 36,
    menuButtonLeft: 26,
    menuButtonSize: 7,
    menuButtonTop: 26
  },
  mapToolbar: MEDIUM_STYLE_BUTTON,
  miniMapToolbar: {
    buttonSize: 29,
    menuButtonLeft: 22,
    menuButtonSize: 5.5,
    menuButtonTop: 22
  },
  threeDVToolbar: MEDIUM_STYLE_BUTTON,
  xdaToolbar: MEDIUM_STYLE_BUTTON
};

export interface RightSideDropdownProps {
  dropdownProps: DropdownProps;
  menu: {
    isDropdownVisible: boolean;
    setIsDropdownVisible: () => void;
    tooltip: string;
  };
}

type ToolbarButtonT = {
  // Determines the active state of the button, and is passed to onToggle
  active: boolean;
  activeIcon?: ReactNode;
  className?: string;
  icon: ReactNode;
  // Gives the indicator that the button opens a menu
  isMenuButton?: boolean;
  // Can be used to toggle the buttons state, e.g. active/inactive, or simply to trigger an action
  onToggle?: (b: boolean, e?: Event) => void;
  overflowLabel?: string;
  rightSideDropdown?: RightSideDropdownProps;
  // Determines the style of the button, depending on its use case (chart, map, etc.)
  styleKey?: keyof typeof STYLE_MAP;
  testId?: string;
  tooltipPlacement?: "top" | "bottom" | "left" | "right";
  tooltipText?: string | ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};

const ToolbarButton = ({
  active = false,
  activeIcon = null,
  className = "",
  disabled = false,
  icon,
  isMenuButton = false,
  onToggle = null,
  overflowLabel = "",
  rightSideDropdown,
  styleKey = "default",
  testId,
  tooltipPlacement = "top",
  tooltipText = "",
  ...rest
}: ToolbarButtonT) => {
  const buttonClassNames = classnames("toolbar-button", className);
  const styleMap = STYLE_MAP[styleKey];

  return (
    <ButtonWrapper hasSidebar={!!rightSideDropdown} styleKey={styleKey}>
      <Tooltip title={tooltipText} placement={tooltipPlacement}>
        <StyledButton
          active={!disabled && active}
          aria-pressed={!disabled && active}
          buttonSize={styleMap.buttonSize}
          className={buttonClassNames}
          data-testid={testId}
          data-qa={testId}
          disabled={disabled}
          onClick={(e) => onToggle(!active, e)}
          {...rest}>
          {activeIcon && active ? activeIcon : icon}
          {overflowLabel}
          {isMenuButton && (
            <MenuButtonIndicator
              active={active}
              size={styleMap.menuButtonSize}
              top={styleMap.menuButtonTop}
              left={styleMap.menuButtonLeft}
            />
          )}
        </StyledButton>
      </Tooltip>

      {rest.rightSideDropdown && (
        <Tooltip title={rest.rightSideDropdown.menu.tooltip}>
          <DropdownWrapper
            className="dropdown-wrapper"
            isVisible={rest.rightSideDropdown.menu.isDropdownVisible}>
            <Dropdown {...rest.rightSideDropdown.dropdownProps}>
              <RightSideButton
                disabled={disabled}
                onClick={() => rest.rightSideDropdown.menu.setIsDropdownVisible()}>
                <RightSideButtonIcon active={active} />
              </RightSideButton>
            </Dropdown>
          </DropdownWrapper>
        </Tooltip>
      )}
    </ButtonWrapper>
  );
};

export default memo(ToolbarButton);

const MenuButtonIndicator = styled.div<{
  active: boolean;
  size: number;
  top: number;
  left: number;
}>`
  position: absolute;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  width: 0;
  height: 0;

  border-left: ${(props) => props.size}px solid transparent; /* Adjust triangle size */
  border-bottom: ${(props) => props.size}px solid;
  border-bottom-color: ${(props) =>
    props.active ? BUTTON_ACTIVE_COLOR : BUTTON_DEFAULT_COLOR};
`;

// Visibility can be controlled in the parent element if needed
// Just used in the maptoolbar for now, styles (position) may need to be adjusted for other use cases
const DropdownWrapper = styled.div<{ isVisible: boolean }>`
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: visibility 0s, opacity 0.15s ease-in-out;

  width: 12px;
  position: fixed;
  transform: translatex(283%) translateY(5%);

  background: var(--color-surface-01);
  &:hover {
    background: var(--color-background-complementary);
  }

  border-left: 1px solid var(--color-surface-04);
  border-bottom-right-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);

  box-shadow: 0 2px 8px var(--fume);
  box-shadow: inset 0 0 0 0 transparent, 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  clip-path: inset(0 -15px 0 0);
`;

const ButtonWrapper = styled.div<{
  hasSidebar: boolean;
  padding: number;
}>`
  display: ${(props) => (props.hasSidebar ? "flex" : "block")};
  position: relative;
  &:hover ${MenuButtonIndicator} {
    border-bottom-color: ${BUTTON_HOVER_COLOR};
  }
  &:hover ${DropdownWrapper} {
    visibility: visible;
    opacity: 1;
  }
`;

const StyledButton = styled.button<{ active: boolean; buttonSize: number }>`
  display: grid;
  width: ${(props) => props.buttonSize}px;
  height: ${(props) => props.buttonSize}px;
  grid-template-columns: ${(props) => props.buttonSize}px max-content;
  align-items: center;
  justify-items: center;
  gap: 6px;

  border: none;
  border-radius: var(--border-radius);

  background: ${(props) =>
    props.active ? BUTTON_ACTIVE_BACKGROUND_COLOR : BUTTON_DEFAULT_BACKGROUND_COLOR};
  color: ${(props) => (props.active ? BUTTON_ACTIVE_COLOR : BUTTON_DEFAULT_COLOR)};

  &:not(:disabled):hover {
    color: ${(props) => (props.danger ? BUTTON_HOVER_COLOR_DANGER : BUTTON_HOVER_COLOR)};
  }

  font-weight: 500;
  padding: 0;
  margin: 0;
  transition: color 0.15s ease-out;
  overflow: hidden;
  pointer-events: all;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const RightSideButtonIcon = styled.div`
  padding-top: 20px;
  left: 2px;

  border-left: 5px solid transparent; /* Adjust triangle size */
  border-bottom: 5px solid;
  border-bottom-color: ${BUTTON_DEFAULT_COLOR};
`;

const RightSideButton = styled.button`
  width: 10px;
  height: 28px;

  display: flex;

  border: none;
  border-radius: var(--border-radius);

  background: ${BUTTON_DEFAULT_BACKGROUND_COLOR};
  color: ${BUTTON_DEFAULT_COLOR};

  &:hover ${RightSideButtonIcon} {
    border-bottom-color: ${BUTTON_HOVER_COLOR};
  }

  padding: 0;
  margin: 0;
  transition: color 0.15s ease-out;
  overflow: hidden;
  pointer-events: all;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
`;
