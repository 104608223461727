// Create and export a single instance of BroadcastChannel
const broadcast = new BroadcastChannel("auth_channel");

export const broadcastTypes = {
  TOKEN_UPDATED: "token_updated",
  TOKEN_REFRESHING: "token_refreshing"
};

// Helper function to broadcast messages
export const broadcastMessage = (type, data) => {
  broadcast.postMessage({ type, ...data });
};

export default broadcast;
